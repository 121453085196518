import React from "react"
import Layout from "../components/layout.js"
import * as styles from "./register.module.css"
import Meta from "../components/meta.js"
export default function RegisterInter() {
    return (
        /*<div>
            <head>
                <title>HTML Meta Tag</title>
                {/!*<meta
              http-equiv="refresh"
              content="2; url = https://chat.whatsapp.com/JF3KYVUTO668fZShlNBO7T
            />*!/}
            </head>
            <Layout />
            <div
                style={{
                    width: `100%`,
                }}
            >
                {/!* <p>Redirecting to form</p> *!/}
                <iframe className={styles.frame}
                        style={{
                            display: `block`,
                            margin: `3rem auto`,
                            height: `550px`,
                        }}
                        src="https://docs.google.com/forms/d/e/1FAIpQLSdTFVLT7NB59soMCnOCHW2L6WROYnGpxBbvSwiSL4Gh0Eo6cQ/viewform"
                        width="100%"
                        frameborder="0"
                        marginwidth="0"
                >
                    Loading…
                </iframe>
            </div>
        </div>*/
        <Layout>
            <Meta />
            <meta http-equiv="refresh" content="0;url=https://chat.whatsapp.com/JF3KYVUTO668fZShlNBO7T" />
        </Layout>
    )
}
